import React from 'react'

export const RemoveFromList = () => {
  return (
    <>
    <div className="mylist">
        <p>Remove From List -</p>
    </div>
</>
  )
}
