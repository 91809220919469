import React from 'react'

export const AddToList = () => {
  return (
    <>
        <div className="mylist">
            <p>Add To List +</p>
        </div>
    </>
  )
}
